import { defineStore } from "pinia";
import { plainToClass } from "class-transformer";
import { Place, PlaceType } from "@/models/place";

export const usePlaceStore = defineStore("place", {
  state: () => ({
    dogRunList: [],
    dogCafeList: [],
    dogHotelList: [],
    petSalonList: [],
    campsiteList: [],
    place: null,
  }),
  actions: {
    async fetchLatest(placeType: PlaceType) {
      const { $_ } = useNuxtApp();
      const { places: json } = await apiFetch(`/places/latest`, {
        query: { "place_type_tags[]": [placeType] },
      });
      const key = `${$_.camelCase(placeType)}List`;
      this[key] = plainToClass(Place, json);
    },
    async fetch(id: number) {
      const { place: json } = await apiFetch(`/places/${id}`);

      this.place = plainToClass(Place, json);
    },
  },
});
